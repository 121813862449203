<template>
    <div class="saved-tasks">
        <b-container fluid class="mb-3">
            <b-row>
                <h1 class="text-center my-3 px-3 p-sm-0 py-0 py-md-3 font-weight-bold w-100">{{ $t('saved_tasks_heading') }} <small class="subheading font-weight-bold">({{ tasks.length }})</small></h1>
            </b-row>
            <b-row class="card-holder">
                <div v-if="tasksFetched" class="w-100">
                    <div v-if="tasks.length > 0" class="text-left flex-row">
                        <div v-for="(task, index) in tasks" v-bind:key="task.hash" class="cols-12 col-sm-6 col-md-4 col-lg-3 px-sm-2 mb-3 card-item">
                            <Card
                              :isFeaturedTask="false"
                              :deliveryTime="task.packages[0].delivery"
                              :index="index"
                              :taskImage="formatMobileUrl(task.assets[0]['path'], task.assets[0]['type'])"
                              :carousel-image="task"
                              :image-icon="task.user.photo_path"
                              :username="task.user.username"
                              :description="task.title"
                              :rating="task.review_average"
                              :count="task.review_count"
                              :user_rating="task.user.review_average"
                              :user_count="task.user.review_count"
                              :user_online="task.user.last_active ? isUserOnline(task.user.last_active) : false"
                              :price="task.packages[0].price"
                              :select="true"
                              :hash="task.hash"
                              :slug="task.slug"
                              :saveTasksPage="true"
                              :sellerInfo="task.user"
                              @removedSavedTask="removeTask"
                              class="mx-auto my-sm-0"
                            />
                        </div>
                    </div>
                    <div v-else class="mx-auto no-saved-tasks">
                        <h1>{{ $t('saved_tasks_empty_heading') }}</h1>
                        <h2> {{ $t('saved_tasks_empty_message') }}</h2>
                        <i class="mb-3 fa-solid fa-heart fa-4x" aria-hidden="true"></i>
                    </div>
                </div>
                <div v-else class="mx-auto my-5">
                    <b-spinner large label="Large Spinner"></b-spinner>
                    <div>{{ $t('loading_tasks') }}</div>
                </div>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import ApiService from '../../apiService.js'
import Card from '../../components/Task/Card'
import helper from '../../helper'

export default {
  name: 'SavedTasks',
  components: { Card },
  data () {
    return {
      tasks: [],
      tasksFetched: false
    }
  },
  mounted () {
    this.getFavouriteTasks()
  },
  created () {
    window.addEventListener('scroll', helper.pauseVideoOnScroll)
  },
  methods: {
    formatMobileUrl (filename, filetype) {
      return helper.formatMobileUrl(filename, filetype)
    },
    getFavouriteTasks () {
      this.tasksFetched = false
      ApiService.favouriteTasks().then(response => {
        this.tasksFetched = true
        this.tasks = response.data.data[0]
      }).catch((error) => {
        this.tasksFetched = true
        ApiService.showError(error)
      })
    },
    removeTask (index) {
      if (this.tasks.length > index) {
        this.tasks.splice(index, 1)
      }
    },
    isUserOnline (date) {
      return helper.isUserOnline(date)
    }
  }
}
</script>

<style scoped>
    .subheading {
        color: dimgrey;
    }
    .no-saved-tasks {
        color: lightslategray;
    }
    .no-carousel {
      display: contents;
    }
    @media (max-width: 991px) {
        .page-heading {
            border: none;
            text-align: center !important;
        }
    }
</style>
