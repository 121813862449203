import { render, staticRenderFns } from "./SavedTasks.vue?vue&type=template&id=78987a39&scoped=true&"
import script from "./SavedTasks.vue?vue&type=script&lang=js&"
export * from "./SavedTasks.vue?vue&type=script&lang=js&"
import style0 from "./SavedTasks.vue?vue&type=style&index=0&id=78987a39&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78987a39",
  null
  
)

export default component.exports